<template>
  <div id="app">
    <sign-canvas ref="SignCanvas" v-model="value" class="sign-canvas" :options="options" />
    <van-row style="margin-top:16px">
      <van-col span="8" style="text-align:center">
        <van-button
          type="info"
          size="normal"
          class="gobackrem"
          @click="backTo()"
        >返回</van-button>
      </van-col>
      <van-col span="8" style="text-align:center">
        <van-button
          type="info"
          size="normal"
          class="restartsignature"
          @click="canvasClear()"
        >重新签名</van-button>
      </van-col>
      <van-col span="8" style="text-align:center">
        <van-button
          type="info"
          size="normal"
          class="submitsignature"
          @click="saveAsImg()"
        >提交签名</van-button>
      </van-col>
    </van-row>
    <van-row v-if="across" style="margin-top:16px">
      <van-col span="24" style="text-align:center;font-size:14px;color:#FFA202;">
        <p>
          提示:为保证更好的操作体验,建议您横屏签名
          <img
            src="../../assets/images/Rotatescreen.svg"
            alt
            style="width:14px;color:orange;vertical-align: top;"
          >
        </p>
      </van-col>
    </van-row>
  </div>
</template>
<script>
export default {
  inject: ['reload'],
  data() {
    return {
      timer: false,
      across: true,
      value: null,
      opinion: '',
      highcroll: 0,
      // 屏幕宽度
      screenWidth: document.body.clientWidth,
      // 屏幕高度
      screenHeight: document.documentElement.clientHeight,
      options: {
        isSign: true, // 签名模式 [Boolean] 默认为非签名模式,有线框, 当设置为true的时候没有任何线框
        isShowBorder: false, // 是否显示边框 [可选]
        canvasWidth: window.innerWidth - 10, // canvas宽高 [Number] 可选
        canvasHeight: 200 // 高度  [Number]
      }
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #EFF6FF')
  },
  created() {
    this.opinion = this.$route.query.auditOpinion
  },
  beforeDestroy() {
    // 移除监听
    // window.removeEventListener("resize", this.renderResize, false)
    // alert('移除成功')
  },
  mounted() {
    // 监听resize方法
    window.addEventListener('resize', this.renderResize, false)
    // alert('监听成功')
    // window.onresize = () => {
    //   return (() => {
    //     window.screenWidth = document.body.clientWidth
    //     this.screenWidth = window.screenWidth
    //   })()
    // }
  },
  updated() {
    // 监听resize方法
    // window.addEventListener("resize", this.renderResize, false);
  },
  // watch:{
  //   screenWidth(val){
  //     alert('宽度变化了')
  //     // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
  //     if(!this.timer){
  //       this.timer = true
  //       this.screenHeight = document.documentElement.clientHeight
  //       if (val < this.screenHeight) {
  //         this.across = false;
  //       }
  //       this.options.canvasWidth = window.innerWidth - 10; //canvas宽高 [Number] 可选
  //       alert('123===' + this.options.canvasWidth)
  //       setTimeout(function(){
  //         // 打印screenWidth变化的值
  //         this.timer = false
  //       }, 400)
  //     }
  //   }
  // },
  methods: {
    renderResize() {
      // alert('进入resize方法')
      // 判断横竖屏
      const width = document.documentElement.clientWidth
      const height = document.documentElement.clientHeight
      if (!this.timer) {
        this.timer = true
        // alert(width+"宽高"+height)
        if (width > height) {
          // alert('横屏')
          window.location.reload()
          this.options.canvasWidth = window.innerWidth - 10 // canvas宽高 [Number] 可选
        } else {
          // alert('竖屏')
          this.across = false
          window.location.reload()
          this.options.canvasWidth = window.innerWidth - 10 // canvas宽高 [Number] 可选
        }
        setTimeout(function() {
          // 打印screenWidth变化的值
          this.timer = false
        }, 400)
      }
      // 做页面适配
      // 注意：renderResize方法执行时虚拟dom尚未渲染挂载，如果要操作vue实例，最好在this.$nextTick()里进行。
    },
    /**
     * 清除画板
     */
    canvasClear() {
      this.$refs.SignCanvas.canvasClear()
    },

    /**
     * 保存图片
     */
    saveAsImg() {
      const img = this.$refs.SignCanvas.saveAsImg()
      // alert(`image 的base64：${img}`);
      var tolist = JSON.parse(localStorage.getItem('tolist'))
      window.removeEventListener('resize', this.renderResize, false)
      // alert('移除成功')
      this.$router.push({
        path: '/reimbursementDetails',
        query: {
          signImgUrl: img,
          id: tolist.id,
          bizType: tolist.bizType,
          sector: tolist.sector,
          opinion: this.opinion,
          name: 'todo'
        }
      })
    },

    /**
     * 保存图片
     */
    backTo() {
      // alert(`image 的base64：${img}`);
      var tolist = JSON.parse(localStorage.getItem('tolist'))
      this.$router.push({
        path: '/reimbursementDetails',
        query: {
          id: tolist.id,
          bizType: tolist.bizType,
          sector: tolist.sector,
          opinion: this.opinion,
          name: 'todo'
          // highcroll :this.highcroll
        }
      })
    },

    /**
     * 下载图片
     */
    downloadSignImg() {
      this.$refs.SignCanvas.downloadSignImg()
    }
  }
}
</script>
<style lang="less" scoped>
// body {
// padding-bottom: env(safe-area-inset-bottom);
// }
* {
  margin: 0;
  padding: 0;
}
.sign-canvas {
  display: block;
  margin: 0 auto;
  border: 1px dashed #f00;
}
.view-image {
  display: block;
  margin: 20px auto;
}
.sign-btns {
  // width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  #clear,
  #clear1,
  #save {
    margin: 0 auto;
    display: inline-block;
    padding: 5px 10px;
    width: 150px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #eee;
    background: #e1e1e1;
    border-radius: 10px;
    text-align: center;
    margin: 20px auto;
    cursor: pointer;
  }
}
.goback {
  width:100px;
  border-radius:5px;
  background: #f4f4f5;
  border-color: #d3d4d6;
  color: #909399;
}
.restartsignature {
  width:100px;
  border-radius:5px;
  background: #f4f4f5;
  border-color: #d3d4d6;
  color: #909399;
}
.submitsignature {
  width:100px;
  border-radius:5px;

}
// @media screen and (orientation: portrait){//竖屏状态下
//     #app{
//         position:absolute;
//         width: 100vh;
//         height: 100vw;
//         top: 0;
//         left: 100vw;
//         -webkit-transform:rotate(90deg);
//         -moz-transform: rotate(90deg);
//         -ms-transform: rotate(90deg);
//         transform:rotate(90deg);
//         transform-origin: 0% 0%;
//     }
// }
// @media screen and (orientation: landscape) {//横屏状态下
//     #app {
//         position:absolute;
//         top:0;
//         left:0;
//         width: 100vw;
//         height: 100vh;
//     }
// }
</style>
<style>
.gobackrem {
  width:100px;
  border-radius:5px;
  background: #f4f4f5;
  border-color: #d3d4d6;
  color: #909399;
}
.restartsignature {
  width:100px;
  border-radius:5px;
  background: #f4f4f5;
  border-color: #d3d4d6;
  color: #909399;
}
.submitsignature {
  width:100px;
  border-radius:5px;

}
</style>
